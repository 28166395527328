import Plot from "react-plotly.js";

import { plotlyConfig, plotlyLayout } from "./plotly-basics";

export const DegenPlotly = (props) => {
  const config = props.config || {};
  const layout = props.layout || {};
  return (
    <Plot
      useResizeHandler
      data={props.data || [{ type: "bar" }]}
      config={{ ...plotlyConfig, ...config }}
      layout={{
        ...plotlyLayout,
        ...layout,
      }}
    />
  );
};
