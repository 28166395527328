import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class HashmaskIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="hash-mask"
        src="https://datastudio.google.com/embed/reporting/27448e37-daed-416f-a273-52a549d6726d/page/3ve4B"
      />
    );
  }
}
