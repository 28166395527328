import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class FidenzaIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Fidenza"
        src="https://datastudio.google.com/embed/reporting/fad39999-1dbf-46b0-88c4-a843503fa4de/page/i4rRC"
      />
    );
  }
}
