import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class CryptoadzIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Cryptoadz"
        src="https://datastudio.google.com/embed/reporting/1046b3b5-d752-467a-8bf7-97ee58ff0ffa/page/JYkbC"
      />
    );
  }
}
