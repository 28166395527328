import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class AutoglyphIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Autoglyph"
        src="https://datastudio.google.com/embed/reporting/b15bb194-16f3-49e8-812c-c80295528b9f/page/IsbWC"
      />
    );
  }
}
