import React from "react";

import Web3 from "web3";

import { Layout } from "../layout/layout";
import { MOONCATFLEX_ABI, MOONCATFLEX_CONTRACT } from "./mooncatFlex";
import { HASHMASKFLEX_ABI, HASHMASKFLEX_CONTRACT } from "./hashmaskFlex";
import { PUNKFLEX_ABI, PUNKFLEX_CONTRACT } from "./punkFlex";
import { CurrentFlexCard } from "./current-flex-card";

import "./flex.scss";
export class FlexProtocol extends React.Component {
  componentWillMount() {
    this.loadBlockchainData();
  }

  async loadBlockchainData() {
    const web3 = new Web3(
      "https://mainnet.infura.io/v3/048fcf5a1fac4c8bbd585c6fe75e6ea0"
    );

    const mooncat = new web3.eth.Contract(
      MOONCATFLEX_ABI,
      MOONCATFLEX_CONTRACT
    );
    const hashmask = new web3.eth.Contract(
      HASHMASKFLEX_ABI,
      HASHMASKFLEX_CONTRACT
    );

    const punk = new web3.eth.Contract(PUNKFLEX_ABI, PUNKFLEX_CONTRACT);
    this.setState({
      mooncat,
      hashmask,
      punk,
    });

    const moonFlex = await mooncat.methods.flex().call();
    const hashmaskFlex = await hashmask.methods.flex().call();
    const punkFlex = await punk.methods.flex().call();

    this.setState({
      mooncatFlexer: moonFlex[0],
      mooncatFlexAmount: moonFlex[1],
      mooncatFlexing: moonFlex[2],
      mooncatTimeLeft: moonFlex[3],
      hashmaskFlexer: hashmaskFlex[0],
      hashmaskFlexAmount: hashmaskFlex[1],
      hashmaskFlexing: hashmaskFlex[2],
      hashmaskTimeLeft: hashmaskFlex[3],
      punkFlexer: punkFlex[0],
      punkFlexAmount: punkFlex[1],
      punkFlexing: punkFlex[2],
      punkTimeLeft: punkFlex[3],
    });
  }

  handleClick() {
    console.log("we clicking");
  }

  constructor(props) {
    super(props);
    this.state = {
      mooncatFlexer: "",
      mooncatFlexAmount: null,
      mooncatFlexing: "",
      mooncatTimeLeft: "",
    };
  }

  render() {
    console.log(MOONCATFLEX_ABI);
    const moonContractUrl =
      "https://etherscan.io/address/0x51db11b7c286598e22173eeb50e969282c05051f#writeContract";
    const hashMaskUrl =
      "https://etherscan.io/address/0x8f5b41470467913c6173092063b50315def8488a#writeContract";
    const punkUrl =
      "https://etherscan.io/address/0x589180d2c325f15a74b6b6effc577fb3950e9bb3#writeContract";
    return (
      <Layout showLeftNav={false}>
        {/* <div className="flex-call-outs">
          <CurrentFlexCard
            title="MoonCat Rescue"
            amount={this.state.mooncatFlexAmount}
            flexer={this.state.mooncatFlexer}
            timeLeft={this.state.mooncatTimeLeft}
            flexing={this.state.mooncatFlexing}
            flexUrl={moonContractUrl}
            imgUrl="https://mooncat.pro"
          />
          <CurrentFlexCard
            title="Hashmask"
            amount={this.state.hashmaskFlexAmount}
            flexer={this.state.hashmaskFlexer}
            timeLeft={this.state.hashmaskTimeLeft}
            flexing={this.state.hashmaskFlexing}
            flexUrl={hashMaskUrl}
            imgUrl="https://www.thehashmasks.com/detail"
          />
          <CurrentFlexCard
            title="CryptoPunks"
            amount={this.state.punkFlexAmount}
            flexer={this.state.punkFlexer}
            timeLeft={this.state.punkTimeLeft}
            flexing={this.state.punkFlexing}
            flexUrl={punkUrl}
            imgUrl="https://www.larvalabs.com/cryptopunks/details"
          />
        </div> */}
        <div className="about-layout">
          <div className="about-content">
            <div className="title">DegenData is for sale</div>

            <section>
              <div className="standout">
                <h1>DegenData is for Sale </h1>
                <p>
                  NFTs are so much more than JPEGs. They represent community,
                  status, access and so much more.
                </p>
                <p>
                  We want to push the boundries of what an NFT is. The sale of
                  DegenData will be faciliated by selling our company CryptoPunk
                  (#5761). This will be the first business sold as an NFT.
                </p>
                We truely belive that the future of IP, brand and ownership will
                be done via NFTs.
              </div>
              <div className="standout">Sales Details:</div>

              <div className="standout">
                DeGen Data introduces the FLEX Protocol. IF YOU GOT IT, FLEX IT.
                The one and ONLY protocol to OFFICIALLY FLEX your NFT ownership.
                Here is how it works. We have launched a few smart contract
                (ever heard of them?) which will allow owners of an NFT to
                Officially FLEX their NFT. Read more about
                <a
                  href="https://degendata.medium.com/nft-flex-protocol-v0-1-by-degen-data-a0ed77936e9e"
                  target="_blank"
                  rel="noreferrer"
                >
                  FLEX Protocol V0.1
                </a>
              </div>
              <hr></hr>
              <br></br>
              <div className="standout">
                Rules: If you decide to FLEX. You must follow a few rules or
                else your FLEX wont go through.
                <ol>
                  <li>
                    Read:
                    <a
                      href="https://degendata.medium.com/nft-flex-protocol-v0-1-by-degen-data-a0ed77936e9e"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FLEX Protocol V0.1
                    </a>
                  </li>
                  <li>
                    Wait until the Flex Timer says "Go Flex". Every Flex gets a
                    guaranteed 24hrs of FLEX time at least. If no one ever out
                    FLEXs they their NFT stays up.
                  </li>
                  <li>
                    If you see "GO FLEX" and you wanna FLEX then click that
                    button!... You GOT OUT FLEX the previous FLEX value in ETH.
                    So make sure you FLEX more than "next flex needs to be
                    greater than".
                  </li>
                  <li>
                    If you do successfully out-FLEX an NFT then enjoy your new
                    spot amongst your family in the Fraternity of FLEX. And know
                    that the feeling of NFT superiority is something only few
                    wish the could feel.
                  </li>
                </ol>
              </div>

              <div className="standout">
                DeGen Data Verified Flex Contracts
                <div>
                  <a
                    href="https://etherscan.io/address/0x589180d2c325f15a74b6b6effc577fb3950e9bb3#writeContract"
                    target="_blank"
                    rel="noreferrer"
                  >
                    OFFICIAL CryptoPunk FLEX Contract
                  </a>
                </div>
                <a
                  href="https://etherscan.io/address/0x8f5b41470467913c6173092063b50315def8488a#writeContract"
                  target="_blank"
                  rel="noreferrer"
                >
                  OFFICIAL Hashmask FLEX Contract
                </a>
                <div>
                  <a
                    href="https://etherscan.io/address/0x51db11b7c286598e22173eeb50e969282c05051f#writeContract"
                    target="_blank"
                    rel="noreferrer"
                  >
                    OFFICIAL MoonCat Rescue FLEX Contract
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}
