import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class ArtBlocksFactoryIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="ArtblocksFactory"
        src="https://datastudio.google.com/embed/reporting/51a1c036-2949-4085-a068-5a9485a98e07/page/AuCXC"
      />
    );
  }
}
