import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class MoonCatIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="mooncats"
        src="https://datastudio.google.com/embed/reporting/c2afa529-b1ff-4bb8-a4fa-b193ef26a571/page/XpOaC"
      />
    );
  }
}
