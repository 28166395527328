import { contracts } from "../../projects/projects";
import { getWeb3 } from "./get-web3";
let minABI = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  // decimals
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    type: "function",
  },
];

export async function getProjects(address) {
  const web3 = await getWeb3();
  const promises = contracts
    .map((project) => {
      return {
        ...project,
        project: new web3.eth.Contract(minABI, project.address),
        name: project.name,
      };
    })
    .map((formatted) => {
      return formatted.project.methods
        .balanceOf(address)
        .call()
        .then((score) => {
          return { ...formatted, score: Number(score), name: formatted.name };
        });
    });
  return await Promise.all(promises);
}
export async function getProjectsForAccount(address) {
  const web3 = await getWeb3();
  const promises = contracts
    .map((contract) => {
      return {
        ...contract,
        project: new web3.eth.Contract(minABI, contract.address),
        name: contract.name,
      };
    })
    .map(async (formatted) => {
      const s = await formatted.project.methods
        .balanceOf(address)
        .call()
        .then((score) => {
          if (Number(score) > 0) {
            return formatted.address;
          } else {
            return null;
          }
        });

      return s;
    })
    .filter((x) => !!x);

  return await Promise.all(promises);
}
