import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class MekaVerseIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="MekaVerse"
        src="https://datastudio.google.com/embed/reporting/3a50353a-0ce6-4bf8-afa8-2a3372812843/page/ForcC"
      />
    );
  }
}
