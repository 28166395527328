export const MOONCATFLEX_CONTRACT =
  "0x51Db11B7c286598e22173eEB50e969282c05051F";
export const MOONCATFLEX_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "_flexer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes5",
        name: "_id",
        type: "bytes5",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_days",
        type: "uint256",
      },
    ],
    name: "NewFlex",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "_address",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes5",
        name: "_id",
        type: "bytes5",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_days",
        type: "uint256",
      },
    ],
    name: "RevokeFlex",
    type: "event",
  },
  {
    constant: true,
    inputs: [],
    name: "contractBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "flex",
    outputs: [
      {
        internalType: "address",
        name: "flexer",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "flexAmount",
        type: "uint256",
      },
      {
        internalType: "bytes5",
        name: "id",
        type: "bytes5",
      },
      {
        internalType: "uint256",
        name: "flexEndTime",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "bytes5",
        name: "_id",
        type: "bytes5",
      },
    ],
    name: "flexMooncat",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "bool",
        name: "_bool",
        type: "bool",
      },
    ],
    name: "pause",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "bytes5",
        name: "_id",
        type: "bytes5",
      },
    ],
    name: "revokeFlex",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "withdraw",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];
