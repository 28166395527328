export const chartColors = {
  red: "#ff3f3f",
  pink: "#ff48c4",
  blue: "#2bd1fc",
  yellow: "#f3ea5f",
  purple: "#c04df9",
  orange: "#ff9654",
  white: "#ffffff",
  black: "#000000",
  green: "#5FFF47",
  lime: "#b3f26b",
};

export const themeColors = {
  primary: chartColors.pink,
  secondary: chartColors.blue,
  highlight: chartColors.green,
};
