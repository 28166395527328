import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class RedLionIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Redlion"
        src="https://datastudio.google.com/embed/reporting/53862b4d-ae95-4115-b90c-abe5e5cfc919/page/XFnaC"
      />
    );
  }
}
