import React, { Component, useEffect, useRef } from "react";

import { Share, Timeline } from "react-twitter-widgets";

import {
  DownloadOutlined,
  EllipsisOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { toPng } from "html-to-image";

import { DegenPlotly } from "../charts/degen-plotly";
import { Layout } from "../layout/layout";
import { calcScore } from "./calc-score";
import { getProjects } from "./get-projects";
import { getWeb3 } from "./get-web3";

import { themeColors } from "../../styles/chart-colors";
import MetaMask from "../../assets/company-logos/meta-mask.png";
import MJ from "../../assets/imgs/mj.png";
import { numberFormatter } from "../../utils/number-formatter";
import { GlobalAccountState } from "../../contexts/account-context";

import "./score-board.scss";

let key = 2;
export class ScoreBoard extends Component {
  state = {
    account: "",
    calculated: false,
    loading: false,
    projects: [],
    hasWeb3: false,
    attemptedAddress: false,
    showMenu: false,
    pic: null,
    showTweet: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      account: "",
      calculated: false,
      loading: false,
      projects: [],
      hasWeb3: false,
      attemptedAddress: false,
      showMenu: false,
      pic: null,
      showTweet: false,
    };
  }
  async componentDidMount() {
    if (this.props.address) {
      let web3 = await getWeb3();
      if (!web3) {
        this.setState({ hasWeb3: false, attemptedAddress: true });
      } else {
        this.setState({
          hasWeb3: true,
          attemptedAddress: true,
        });
      }
    }
  }
  screenGrab = async () => {
    const dash = document.getElementById("screen-grab-id");

    const dataUrl = await toPng(dash);

    var link = document.createElement("a");
    link.href = dataUrl;
    link.download = `dashboard-for-${this.state.account}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  connectWallet = async (e) => {
    e.preventDefault();

    let web3 = await getWeb3();
    if (!web3) {
      this.setState({ hasWeb3: false, attemptedAddress: true });
    } else {
      const account = await web3.eth.getAccounts();
      this.setState({
        account: account[0],
        hasWeb3: true,
        attemptedAddress: true,
      });
    }
  };
  getDegenScore = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const projects = await getProjects(this.props.address);
    const { score, projectsWithScore } = calcScore(projects);

    this.setState({ projects: projectsWithScore, overAllScore: score }, () => {
      setTimeout(() => {
        this.setState({ calculated: true, loading: false });
      }, 500);
    });
  };
  renderContent = () => {
    const { calculated, loading, projects, overAllScore } = this.state;

    if (loading) {
      return (
        <div className="loading-container">
          <div className="loading-animate">loading</div>
        </div>
      );
    }
    if (calculated) {
      const names = projects.map(({ name }) => (name || "").toUpperCase());
      const scores = projects.map(({ score }) => score);
      const totals = projects.map(({ total }) => total);
      const amounts = projects.map(({ amount }) => amount);
      const totalOwned = totals.reduce((a, t) => a + t, 0);
      const amountsAvailable = amounts.reduce((a, t) => a + t, 0);

      const percentageOwned = totalOwned / amountsAvailable;

      return (
        <>
          <div className="score-board-total">
            <div className="score-stats">
              <StatCard title="OverAll Degen Score" value={overAllScore} />
              <StatCard title="Total Owned by You" value={totalOwned} />
              <StatCard
                title="Total Available"
                value={numberFormatter(amountsAvailable)}
              />
              <StatCard
                title="Percentage Owned By You"
                value={`${percentageOwned}%`}
              />
            </div>

            <div className="stats-dashboard">
              <div className="image-container">
                <img src={MJ} alt="random" />
              </div>
              <DegenPlotly
                data={[
                  {
                    domain: { x: [0, 1], y: [0, 1] },
                    value: overAllScore,
                    title: { text: "Degen Rating" },
                    type: "indicator",
                    mode: "gauge+number",
                    delta: { reference: 400 },
                    gauge: { axis: { range: [null, 100] } },
                  },
                ]}
                layout={{}}
              />
              <DegenPlotly
                data={[
                  {
                    name: "Project Counts",
                    type: "scatterpolar",
                    r: [...scores, scores[0]],
                    theta: [...names, names[0]],
                    fill: "toself",
                    line: {
                      color: themeColors.primary,
                    },
                    marker: {
                      color: themeColors.primary,
                    },
                  },
                  {
                    name: "Project Weights",
                    type: "scatterpolar",
                    r: [...totals, totals[0]],
                    theta: [...names, names[0]],
                    fill: "toself",
                    line: {
                      color: themeColors.secondary,
                    },
                    marker: {
                      color: themeColors.secondary,
                    },
                  },
                ]}
              />
              <table className="score-board-table">
                <thead className="score-board-header">
                  <tr>
                    <th>Project</th>
                    <th>Score</th>
                    <th>Total</th>
                    <th>% Owned</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody className="score-board-body">
                  {projects.map(({ score, name, amount, total, ...rest }) => {
                    return (
                      <tr key={++key}>
                        <td className="body-cell project-name">{name}</td>
                        <td className="body-cell project-score">{score}</td>
                        <td className="body-cell project-score">{total}</td>
                        <td className="body-cell project-score">
                          {`${total / amount}%`}
                        </td>
                        <td className="body-cell project-score">
                          {numberFormatter(amount)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    }

    return <LandingPageContent />;
  };

  renderControls = () => {
    const { loading, attemptedAddress, hasWeb3 } = this.state;

    if (loading) return null;
    if (attemptedAddress && !hasWeb3) {
      return (
        <div className="score-board-controls">
          <div className="text-content">Oh Snap! Wallet not found!</div>
          <img
            style={{ width: "100px", background: "none", marginLeft: "1em" }}
            src={MetaMask}
            title="Meta Mask Logo"
            alt="meta-mask-logo"
          />
          <div className="meta-mask-button">1. DOWNLOAD META MASK</div>
          <div className="meta-mask-button">2. CONNECT YOUR WALLET</div>
        </div>
      );
    }
    return null;
  };

  createTweet = async () => {
    const dash = document.getElementById("screen-grab-id");
    // dash.style.width = "1000px";
    const pic = await toPng(dash);

    this.setState({ pic, showTweet: true }, () => {
      // dash.style.width = "initial";
    });
    // tweetIt(pic);
  };

  render() {
    const {
      calculated,
      showMenu,

      loading,
      hasWeb3,
      overAllScore,
    } = this.state;
    const { address } = this.props;
    const canCalculate = address && !calculated && hasWeb3;
    return (
      <Layout showLeftNav={false}>
        <div className="center-scoreboard">
          <TweetPreview
            pic={this.state.pic}
            overAllScore={overAllScore}
            show={this.state.showTweet}
            setShow={(showTweet) => this.setState({ showTweet, pic: null })}
          />

          <div className="action-buttons">
            {calculated && (
              <>
                <div className="screen-grab-button" onClick={this.createTweet}>
                  Share <TwitterOutlined className="twitter-icon" />
                </div>
                <div
                  onClick={() => this.setState({ showMenu: !showMenu })}
                  className="screen-grab-button"
                >
                  <EllipsisOutlined className="twitter-icon" />
                  {showMenu && (
                    <ul className="extra-menu">
                      <li
                        onClick={() => this.screenGrab()}
                        className="extra-menu-item"
                      >
                        <DownloadOutlined className="twitter-icon" />
                        <span>Download</span>
                      </li>
                    </ul>
                  )}
                </div>
              </>
            )}

            {canCalculate && (
              <div
                disabled={!loading}
                className="screen-grab-button"
                onClick={this.getDegenScore}
              >
                RATE ME
              </div>
            )}
          </div>
          <div id="screen-grab-id" className="score-board-container">
            <div className="score-board-title"></div>
            {this.renderControls()}
            {this.renderContent()}
          </div>
        </div>
      </Layout>
    );
  }
}

const ConnectMetaMask = (props) => {
  return (
    <div onClick={props.onClick} className="screen-grab-button ">
      Connect
      <img
        className="meta-mask-logo"
        src={MetaMask}
        title="Meta Mask Logo"
        alt="meta-mask-logo"
      />
    </div>
  );
};

function StatCard(props) {
  return (
    <div className="stat">
      <div className="stat-title">{props.title}</div>
      <div className="stat-value">{props.value}</div>
    </div>
  );
}

function LandingPageContent() {
  return (
    <div className="landing-content">
      <h1>THE Degen Rating Project</h1>
      <p>This is the best place to be the best Degen Data Art collector.</p>
      <Timeline
        dataSource={{
          sourceType: "profile",
          screenName: "degendata",
        }}
        options={{
          theme: "dark",
        }}
      />
    </div>
  );
}

function TweetPreview(props) {
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (props.show && ref.current && !ref.current.contains(e.target)) {
        props.setShow(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [props.show]);
  return (
    <span
      cli
      className={`tweet-modal ${props.show ? "show-modal" : "no-modal"}`}
    >
      <div ref={ref} className="preview">
        <h2> Degen Score: {props.overAllScore}</h2>
        <p>
          Check out
          <a
            className="link"
            href="https://www.degendata.io/degen-score"
            target="_blank"
            rel="noreferrer"
          >
            degendata.io
          </a>
          to find out where you land in the world of best NFT collectors!
        </p>
        <div className="image-container">
          <img src={props.pic} alt="degendata-pic" />
        </div>

        <Share
          url=""
          renderError={() => {
            return <p>ERROR: Cannot tweet at the moment.</p>;
          }}
          options={{
            size: "large",
            hashtags: "nft,degendata,degenrating",
            lang: "en",
            text: `Just got my Degen NFT Rating:${props.overAllScore} at degendata.io.. Level: **INSERT CREATIVE NAME**. Check it out! `,
          }}
        />
      </div>
    </span>
  );
}
