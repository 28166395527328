import "./App.scss";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { projects } from "./projects/projects";

import { Layout } from "./components/layout/layout.jsx";

import { GlobalAccountState } from "./contexts/account-context";

import BAYC from "./projects/imgs/bayc.png";
import chromie from "./projects/imgs/chromie-squiggle.png";
import cryptopunks from "./projects/imgs/crypto-punks.png";
import cryptovoxel from "./projects/imgs/cryptovoxel.png";
import fidenza from "./projects/imgs/fidenza.png";
import hashmasks from "./projects/imgs/hashmasks.png";
import meebits from "./projects/imgs/meebit.jpeg";
import mooncats from "./projects/imgs/mooncats.png";
import ringers from "./projects/imgs/ringers.png";
import veefriends from "./projects/imgs/vee-friends.png";
import artblockscurated from "./projects/imgs/chromie.png";
import artblocksplayground from "./projects/imgs/ab-playground.png";
import artblocksfactory from "./projects/imgs/ab-factory.png";
import autoglyph from "./projects/imgs/autoglyph.svg";
import avastars from "./projects/imgs/avastars.png";
import avidlines from "./projects/imgs/avidLines.png";
import twinflames from "./projects/imgs/TwinFlames.jpeg";
import redlion from "./projects/imgs/gazette.jpeg";
import cryptoad from "./projects/imgs/toad_gif.gif";
import meka from "./projects/imgs/meka.png";
import coolcat from "./projects/imgs/coolcat.png";
import forgottenRune from "./projects/imgs/forgotten-rune.png";
import doodle from "./projects/imgs/doodle.png";
import creaturetoad from "./projects/imgs/creaturetoad.png";
import braindrops from "./projects/imgs/braindrops.jpeg";
import www from "./projects/imgs/www-land.png";
import punk5671 from "./components/sale/punk5671.png";
import "./components/sale/sale.scss";

export function getImg(name) {
  switch (name) {
    case "bayc":
    case "BAYC":
      return BAYC;
    case "VeeFriends":
      return veefriends;
    case "CryptoPunks":
      return cryptopunks;
    case "Cryptovoxels":
      return cryptovoxel;
    case "MoonCat Rescue":
      return mooncats;
    case "Meebits":
      return meebits;
    case "MekaVerse":
      return meka;
    case "Hashmasks":
      return hashmasks;
    case "Ringers":
      return ringers;
    case "Fidenza":
      return fidenza;
    case "Chromie Squiggles":
      return chromie;
    case "Art Blocks Curated":
      return artblockscurated;
    case "Coolcats":
      return coolcat;
    case "Art Blocks Playground":
      return artblocksplayground;
    case "Art Blocks Factory":
      return artblocksfactory;
    case "Autoglyphs":
      return autoglyph;
    case "Avastars":
      return avastars;
    case "Avid Lines":
      return avidlines;
    case "Twin Flames":
      return twinflames;
    case "Redlion Gazette":
      return redlion;
    case "Cryptoadz":
      return cryptoad;
    case "Forgotten Rune":
      return forgottenRune;
    case "Doodles":
      return doodle;
    case "CreatureToadz":
      return creaturetoad;
    case "BrainDrops":
      return braindrops;
    case "Worldwide Webb Land":
      return www;
    default:
      console.log("Missing Image For: ", name);
      return BAYC;
  }
}

export function userOwnsProject(project, userProjs) {
  return project.some((p) => {
    return userProjs.includes(p);
  });
}

function App() {
  return (
    <Layout showProjects={false} showLeftNav={false}>
      <div
        className="the-punk"
        style={{ textAlign: "center", marginTop: "150px" }}
      >
        <div style={{ color: "#ff48c4", fontSize: "50px" }}>
          Come hang with us on{" "}
          <a href="https://twitter.com/degendata"> Twitter</a>
        </div>
        <img src={punk5671} width="300" height="300" />
      </div>

      {/* <div className="App">
        <div className="project-grid">
          {projects.map((project) => {
            if (
              isPaid ||
              project.noFeeRequired ||
              userOwnsProject(project.address, userProjects)
            ) {
              return (
                <Link
                  key={project.key}
                  className={`list-item degen-link project-card`}
                  to={`/project${project.path}`}
                >
                  <div className="img-container">
                    <img
                      className="img"
                      alt={project.title}
                      src={getImg(project.title)}
                    />
                  </div>

                  <div className="project-title">{project.title}</div>
                </Link>
              );
            } else {
              return (
                <div
                  key={project.key}
                  className={`list-item degen-link project-card disabled`}
                >
                  <div className="img-container">
                    <img
                      className="img"
                      alt={project.title}
                      src={getImg(project.title)}
                    />
                  </div>

                  <div className="project-title">{project.title}</div>
                </div>
              );
            }
          })}
        </div>
      </div> */}
    </Layout>
  );
}

export default App;
