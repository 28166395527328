import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class ArtBlocksCuratedIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Artblockcurated"
        src="https://datastudio.google.com/embed/reporting/43a33088-499d-447d-be2e-0f5f3e70b4cf/page/p_6z8vrpc2lc"
      />
    );
  }
}
