import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class CoolCatsIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Coolcats"
        src="https://datastudio.google.com/embed/reporting/781ca8b0-59cd-48ee-81dc-559ce7370ac1/page/KMidC"
      />
    );
  }
}
