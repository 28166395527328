import React from "react";
import { FlexAddress } from "./flex-address";
import "./flex.scss";
import { Statistic } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
const { Countdown } = Statistic;
export const CurrentFlexCard = (props) => {
  const { title, amount, flexer, timeLeft, flexing, imgUrl } = props;
  console.log(title, Date.now() > timeLeft);

  console.log(Date.now().toString().slice(0, -3), timeLeft);

  const showFlexButton =
    isNaN(parseFloat(timeLeft)) ||
    !timeLeft ||
    Date.now().toString().slice(0, -3) > parseFloat(timeLeft);
  return (
    <div className="flex-item">
      <div className="flex-item-header">
        <h2>{title} </h2>

        <small>
          DegenData Approved <CheckCircleOutlined />
        </small>
      </div>
      <FlexStatItem
        title="Official Flex God"
        value={<FlexAddress address={flexer} />}
      />

      <div className="flex-stats">
        <FlexStatItem
          title="Next FLEX needs to be greater than:"
          value={`${parseFloat(amount) / 10 ** 18} ETH`}
        />

        <FlexStatItem
          title={`${title} Flexing`}
          value={
            imgUrl ? (
              <a
                className="flex-img-button"
                href={`${imgUrl}/${flexing}`}
                target="__blank"
                rel="noreferrer"
              >
                {flexing}
              </a>
            ) : (
              flexing
            )
          }
        />
        <FlexStatItem
          title="Time Left Until you can Out-FLEX"
          value={
            showFlexButton ? (
              props.flexUrl ? (
                <a
                  className="flex-button"
                  href={props.flexUrl}
                  target="__blank"
                  rel="noreferrer"
                >
                  GO FLEX
                </a>
              ) : (
                <a className="flex-button">NEEDS URL</a>
              )
            ) : (
              <FlexTimeLeft timeLeft={parseFloat(timeLeft)} />
            )
          }
        />
      </div>
    </div>
  );
};

const FlexTimeLeft = (props) => {
  return (
    <Countdown
      valueStyle={{ fontSize: "1rem", fontWeight: "bold" }}
      value={props.timeLeft * 1000}
    />
  );
};

const FlexStatItem = (props) => {
  return (
    <div className="flex-stat-item">
      <div className="title">{props.title}</div>
      <div className="value">{props.value}</div>
    </div>
  );
};

{
  /* <div>
<small>
  Don't try to flex less than this. You'll just lose gas. Each Flex has
  to be higher than the previous.
</small>
</div> */
}
