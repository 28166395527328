import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class SquigglesIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Squiggles"
        src="https://datastudio.google.com/embed/reporting/d522a821-5198-4f63-a64d-228e48f4bb13/page/lPhJC"
      />
    );
  }
}
