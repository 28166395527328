import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class BaycIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="BAYC"
        src="https://datastudio.google.com/embed/reporting/aa4395f5-1d53-46ab-b71a-936a2b6a93af/page/vdaQC"
      />
    );
  }
}
