import { themeColors } from "../../styles/chart-colors";
export const plotlyConfig = {
  responsive: true,
  displayModeBar: false,
  displaylogo: false,
};
export const plotlyLayout = {
  polar: { bgcolor: "transparent" },
  paper_bgcolor: "transparent",
  plot_bgcolor: "transparent",
  font: { color: themeColors.primary, size: 10 },
  autosize: true,
  margin: { b: 40, l: 100, r: 100, t: 40 },
  showlegend: true,
  legend: {
    x: 1,
    xanchor: "right",
    y: 1,
  },
};
export const plotlyTraceBasics = {};
