import "./iframe.scss";
export const IframeComp = (props) => {
  return (
    <div className="iframe-container">
      <div className="iframe-item">
        <iframe
          className="iframe-item-content"
          title={props.title}
          src={props.src}
        ></iframe>
        {/* <div className="iframe-item-content">content</div> */}
      </div>
    </div>
  );
};
