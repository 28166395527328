import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class AvastarsIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Avastars"
        src="https://datastudio.google.com/embed/reporting/03542d59-d91e-4ccd-9292-4bf8de59cf27/page/OOgXC"
      />
    );
  }
}
