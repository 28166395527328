import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Card, PageHeader } from "antd";
import { DegenMobile } from "./components/mobile/mobile";

import App from "./App";
import { AboutDegenData } from "./components/about/about";
import { FlexProtocol } from "./components/flex/flex";
import { DegenSale } from "./components/sale/sale";
import { SalesAssets } from "./components/sales-assets/sales-assets";
import { SalesWhy } from "./components/sales-why/sales-why";
import { projects } from "./projects/projects";
import { Layout } from "./components/layout/layout";
import { ScoreBoard } from "./components/score-board/score-board";
import { Details } from "./components/sale/details";

import "./projects/projects.scss";
import { GlobalAccountState } from "./contexts/account-context";
import { userOwnsProject } from "./App";

export const Router = () => {
  const { address, projects: userProjects, isPaid } = useContext(
    GlobalAccountState
  );

  const routes = projects.map((project) => {
    const {
      noFeeRequired,
      Component,
      exact,
      key,
      path,
      title,
      address,
    } = project;
    if (isPaid || noFeeRequired || userOwnsProject(address, userProjects)) {
      return (
        <Route
          key={key}
          exact={exact}
          path={`/project${path}`}
          render={(props) => {
            return (
              <Layout activeRoute={props.location.pathname}>
                <PageHeader
                  className="project-page"
                  title={<div className="title">{title}</div>}
                >
                  <Card className="page-content">
                    <Component />
                  </Card>
                </PageHeader>
              </Layout>
            );
          }}
        />
      );
    } else {
      return <Redirect key={key} from={`/project${path}`} to="/" />;
    }
  });

  return (
    <Switch>
      {/* <Route exact path="/about" component={AboutDegenData} />
      <Route exact path="/flex" render={() => <FlexProtocol />} />
      <Route exact path="/sale" render={() => <DegenSale />} />
      <Route exact path="/sale/details" render={() => <Details />} />
      <Route exact path="/sale/why" render={() => <SalesWhy />} />
      <Route
        exact
        path="/degen-score"
        render={() => <ScoreBoard address={address} />}
      />
      <Route exact path="/m/" render={DegenMobile} />
      {routes} */}
      <Route path="/" component={App} />
    </Switch>
  );
};
