const nfObject = new Intl.NumberFormat("en-US");
const currObject = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function numberFormatter(val) {
  let formatted = val;
  if (typeof val === "string") {
    formatted = Number(val);
  }
  return nfObject.format(formatted);
}
export function currencyFormatter(val) {
  let formatted = val;
  if (typeof val === "string") {
    formatted = Number(val);
  }
  return currObject.format(formatted);
}
