import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class RingersIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Ringers"
        src="https://datastudio.google.com/embed/reporting/f96d43bb-986c-4b42-ac32-34b1cb4314f8/page/EzSLC"
      />
    );
  }
}
