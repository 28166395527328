import React, { createContext, useReducer } from "react";

// export default ThemeContext;

const initialAccountState = {
  address: "",
  projects: [],
  attemptedConnect: false,
  isPaid: true,
};

export const GlobalAccountState = createContext(initialAccountState);
export const GlobalAccountDispatch = createContext(null);

export const accountActions = {
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_PAID: "SET_PAID",
};

function accountReducer(state, action) {
  switch (action.type) {
    case accountActions.SET_PAID:
      return { ...state, isPaid: action.payload };
    case accountActions.SET_ACCOUNT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const GlobalAccountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialAccountState);

  return (
    <GlobalAccountState.Provider value={state}>
      <GlobalAccountDispatch.Provider value={dispatch}>
        {children}
      </GlobalAccountDispatch.Provider>
    </GlobalAccountState.Provider>
  );
};
