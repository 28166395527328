import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  GlobalAccountDispatch,
  GlobalAccountState,
  accountActions,
} from "../../contexts/account-context";
import Web3 from "web3";
import { Layout } from "../layout/layout";
import { getWeb3 } from "../score-board/get-web3";
import {
  CRYPTOPUNK_MARKET_ABI,
  CRYPTOPUNK_ADDRESS,
} from "./cryptoPunksMarketABI";
import { PAYWALL_ABI, PAYWALL_ADDRESS } from "../../abis/paywall-abi";
import { getProjectsForAccount } from "../score-board/get-projects";
import punk5671 from "./punk5671.png";
import "./sale.scss";

export const Details = () => {
  const accountDispatch = useContext(GlobalAccountDispatch);
  const { address, attemptedConnect, isPaid } = useContext(GlobalAccountState);
  const [punkPrice, setPunkPrice] = useState(null);
  const [punkOwner, setPunkOwner] = useState(null);
  const [saleStart, setSaleStart] = useState(false);
  const [error, setError] = useState(null);

  return (
    <Layout showLeftNav={false}>
      <div className="about-layout">
        <div className="about-content">
          <section>
            <div className="standout">
              <div className="description">
                <h1> Why are we selling DegenData?</h1>
                <p>
                  First, a big thank you to everyone we have met, learned from
                  and explored the NFT space with. We love the NFT space and
                  want to continue to see it grow and thrive. From our corner in
                  the NFT world, the DegenData mission remains - to help NFT
                  traders, investors and researcher make the most informed
                  decisions. This sale is not us leaving crypto... we are far
                  from that. But that being said here is a little bit about why
                  we have decided to sell this Punk + Product.
                </p>
                <p>
                  You might be wondering why we are selling DGD. If DGD has so
                  much potential, smart contract paywall logic already built and
                  a promising future road map, why would we not build-out DGD to
                  its full potential ourselves?
                </p>
                <p>
                  DegenData is owned by Pink Swan Trading, Inc. (PST). PST has
                  another brand it owns and is developing called
                  <a href="www.gvol.io" target="_blank">
                    Genesis Volatility.
                  </a>
                </p>
                <p>
                  Genesis Volatility is a Crypto Options analytics product. We
                  feel an undeniable passion for crypto options, the DeFi space
                  and the nascent DeFi option marketplace. The PST team feels
                  strongly that we can be pioneers in crypto options analytics
                  and pioneers in DeFi crypto options, for that reason we
                  decided to sell DGD and use the proceeds to help build Genesis
                  Volatility to its full potential, especially as we dig deeper
                  into the DeFi world.
                </p>
                <p>
                  As a crypto native team, we believe that selling DGD in a
                  novel way also creates a landmark event for NFTs in general.
                  NFTs are more than JPEGs, they are real world brand names
                  conveying real value, for the first time in liquid form.
                </p>
                <p>
                  We hope to build a strong relationship with the ultimate
                  buyers of DGD and look forward to a fruitful transition period
                  that will help DGD succeed in the long-run.
                </p>
                <p>
                  You can continue to follow us via through our various
                  channels.
                </p>
                <ul>
                  <li>
                    Website:
                    <a href="www.gvol.io" target="_blanks">
                      www.gvol.io
                    </a>
                  </li>
                  <li>
                    Twitter:
                    <a href="https://twitter.com/degendata" target="_blank">
                      @GenesisVol
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://genesisvolatility.substack.com/"
                      target="_blank"
                    >
                      Our Weekly Volatility Newsletter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/c/GenesisVolatility/featured"
                      target="_blank"
                    >
                      GVol Options Education YouTube Channel
                    </a>
                  </li>
                </ul>
                Genesis Volatility plans to build on-chain functionality and
                reward it’s community and user base. Sincerely, PST
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};
