import Web3 from "web3";

export async function getWeb3(customWeb3Option) {
  let web3 = null;
  if (customWeb3Option) {
    web3 = new Web3(customWeb3Option);
    return web3;
  } else if (window.ethereum) {
    web3 = new Web3(Web3.currentProvider || window.ethereum);
    await window.ethereum.enable();
    return web3;
  } else if (window.web3) {
    web3 = new Web3(window.web3.currentProvider);
    return web3;
  }
  return web3;
}
