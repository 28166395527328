import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class BrainDropsIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="BrainDrops"
        src="https://datastudio.google.com/embed/reporting/dca0f25d-03d7-4fe6-8ebe-66d7cb15658a/page/VwrfC"
      />
    );
  }
}
