import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class WorldwideWebbLandIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Worldwide Webb Land"
        src="https://datastudio.google.com/embed/reporting/71c47c72-3cdf-422c-8582-b6c72de7a019/page/3PtiC"
      />
    );
  }
}
