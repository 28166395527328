import { TwitterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import "./footer.scss";

export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="site-map">
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <Link to="/about">About</Link>
        </div>
        <div>{/* <Link to="/degen-score">Degen Rating</Link> */}</div>
      </div>
      <div className="call-outs">
        <div>
          {/* <Link to="/flex">
            <b style={{ marginRight: "2px" }}>FLEX</b>
            YOUR
            <b style={{ marginLeft: "2px" }}>NFT</b>
          </Link> */}
        </div>
      </div>
      <div className="legal">
        <div>
          <a
            href="https://twitter.com/degendata"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterOutlined /> Follow Us
          </a>
        </div>
        <div>@{new Date().getFullYear()}, PinkSwanTrading Inc.</div>
      </div>
    </div>
  );
};
