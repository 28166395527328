import MoonCatIFrame from "../components/mooncat/mooncat";
import HashmaskIFrame from "../components/hashmask/hashmask";
import CryptoPunk from "../components/punks/punks";
import MeebitsIFrame from "../components/meebits/meebits";
import SquigglesIFrame from "../components/chromie-squiggles/squiggles";
import RingersIFrame from "../components/ringers/ringers";
import VeeFriendsIFrame from "../components/veefriends/veefriends";
import BaycIFrame from "../components/bayc/bayc";
import FidenzaIFrame from "../components/fidenza/fidenza";
import ArtBlocksCuratedIFrame from "../components/all-art-blocks/art-blocks";
import ArtBlocksPlaygroundIFrame from "../components/art-blocks-playground/art-blocks-playground";
import ArtBlocksFactoryIFrame from "../components/art-blocks-factory/art-blocks-factory";
import AutoglyphIFrame from "../components/autoglyphs/autoglyph";
import Avastars from "../components/avastars/avastar";
import AvidLinesIFrame from "../components/avid-lines/avid-lines";
import TwinFlamesIFrame from "../components/twin-flames/twin-flames";
import RedLionIFrame from "../components/redlion/redlion";
import CryptoadzIFrame from "../components/cryptoadz/cryptoadz";
import MekaVerseIFrame from "../components/mekaverse/mekaverse";
import CoolCatsIFrame from "../components/coolcats/coolcats";
import ForgottenRuneIFrame from "../components/forgotten-rune/forgotten-run";
import DoodlesIFrame from "../components/doodles/doodles";
import CreatureToadzIFrame from "../components/creaturetoadz/creaturetoads";
import BrainDropsIFrame from "../components/braindrops/braindrops";
import WorldwideWebbLandIFrame from "../components/www-land/www-land";

//
//
// WE CAN HAVE FUN AND MAKE THIS MORE FUN BUT.... 🤷🏽‍♂️🤷🏽‍♂️🤷🏽‍♂️
//
//
export const contracts = [
  {
    name: "mooncats",
    address: "0xc3f733ca98e0dad0386979eb96fb1722a1a05e69",
    amount: 25600,
  },
  {
    name: "autoglyphs",
    address: "0xd4e4078ca3495de5b1d4db434bebc5a986197782",
    amount: 512,
  },
  {
    name: "punks",
    address: "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb",
    amount: 10000,
  },
  {
    name: "hashmasks",
    address: "0xc2c747e0f7004f9e8817db2ca4997657a7746928",
    amount: 16384,
  },
  {
    name: "artBlocksNew",
    address: "0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270",
    amount: 18000,
  },
  {
    name: "artBlocksOld",
    address: "0x059edd72cd353df5106d2b9cc5ab83a52287ac3a",
    amount: 18000,
  },
  {
    name: "VeeFriends",
    address: "0xa3aee8bce55beea1951ef834b99f3ac60d1abeeb",
    amount: 10255,
  },
  {
    name: "Avastars",
    address: "0xF3E778F839934fC819cFA1040AabaCeCBA01e049",
    amount: 25200,
  },
  {
    name: "BAYC",
    address: "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
    amount: 10000,
  },
  {
    name: "meebits",
    address: "0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7",
    amount: 20000,
  },
];

//
//
//
export const projects = [
  {
    title: "CryptoPunks",
    exact: true,
    path: "/cryptopunks",
    key: "cryptopunks",
    address: ["0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb"],
    Component: CryptoPunk,
    photoUrl: "crypto-punks.png",
    noFeeRequired: true,
  },
  {
    title: "Art Blocks Curated",
    exact: true,
    address: [
      "0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270",
      "0x059edd72cd353df5106d2b9cc5ab83a52287ac3a",
    ],
    path: "/art-blocks-curated",
    key: "art-blocks-curated",
    Component: ArtBlocksCuratedIFrame,
    photoUrl: "chromie.png",
  },
  {
    title: "Art Blocks Playground",
    exact: true,
    path: "/art-blocks-playground",
    key: "art-blocks-playground",
    address: [
      "0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270",
      "0x059edd72cd353df5106d2b9cc5ab83a52287ac3a",
    ],
    Component: ArtBlocksPlaygroundIFrame,
    photoUrl: "ab-playground.png",
  },
  {
    title: "Art Blocks Factory",
    exact: true,
    path: "/art-blocks-factory",
    key: "art-blocks-factory",
    address: [
      "0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270",
      "0x059edd72cd353df5106d2b9cc5ab83a52287ac3a",
    ],
    Component: ArtBlocksFactoryIFrame,
    photoUrl: "ab-factory.png",
  },
  {
    title: "BrainDrops",
    exact: true,
    path: "/braindrops",
    key: "braindrops",
    address: ["0xdfde78d2baec499fe18f2be74b6c287eed9511d7"],
    Component: BrainDropsIFrame,
    photoUrl: "braindrops.jpeg",
  },
  {
    title: "Autoglyphs",
    exact: true,
    address: ["0xd4e4078ca3495de5b1d4db434bebc5a986197782"],
    path: "/autoglyphs",
    key: "autoglyphs",
    Component: AutoglyphIFrame,
    photoUrl: "autoglyph.svg",
  },
  {
    title: "VeeFriends",
    exact: true,
    path: "/veefriends",
    key: "veefriends",
    address: ["0xa3aee8bce55beea1951ef834b99f3ac60d1abeeb"],
    Component: VeeFriendsIFrame,
    photoUrl: "vee-friends.png",
  },
  {
    title: "Cryptoadz",
    exact: true,
    path: "/cryptoadz",
    key: "cryptoadz",
    address: ["0x1CB1A5e65610AEFF2551A50f76a87a7d3fB649C6"],
    Component: CryptoadzIFrame,
    photoUrl: "toad_gif.gif",
  },
  {
    title: "CreatureToadz",
    path: "/creaturetoadz",
    key: "creaturetoads",
    exact: true,
    Component: CreatureToadzIFrame,
    address: ["0xA4631A191044096834Ce65d1EE86b16b171D8080"],
    photoUrl: "creaturetoad.jpeg",
  },
  {
    title: "Worldwide Webb Land",
    path: "/worldwide-web-land",
    key: "www",
    exact: true,
    Component: WorldwideWebbLandIFrame,
    //address: ["0xA4631A191044096834Ce65d1EE86b16b171D8080"],
    photoUrl: "www-land.png",
  },

  {
    title: "Avastars",
    path: "/avastars",
    exact: true,
    key: "avastars",
    address: ["0xF3E778F839934fC819cFA1040AabaCeCBA01e049"],
    Component: Avastars,
    photoUrl: "avastars.png",
  },
  {
    title: "Forgotten Rune",
    exact: true,
    path: "/forgotten-rune",
    address: ["0x521f9C7505005CFA19A8E5786a9c3c9c9F5e6f42"],
    key: "ForgottenRune",
    Component: ForgottenRuneIFrame,
    photoUrl: "forgotten-rune.png",
  },
  {
    title: "Doodles",
    path: "/doodles",
    key: "doodles",
    address: ["0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e"],
    exact: true,
    Component: DoodlesIFrame,
    photoUrl: "doodle.png",
  },
  {
    title: "Meebits",
    path: "/meebits",
    key: "meebits",
    exact: true,
    address: ["0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7"],
    Component: MeebitsIFrame,
    photoUrl: "meebit.jpeg",
  },
  {
    title: "MekaVerse",
    path: "/mekaverse",
    key: "mekaverse",
    address: ["0x9A534628B4062E123cE7Ee2222ec20B86e16Ca8F"],
    exact: true,
    Component: MekaVerseIFrame,
    photoUrl: "meka.jpeg",
  },
  {
    title: "Coolcats",
    exact: true,
    path: "/coolcats",
    address: ["0x1A92f7381B9F03921564a437210bB9396471050C"],
    key: "coolcats",
    Component: CoolCatsIFrame,
    photoUrl: "coolcat.png",
  },

  {
    title: "Twin Flames",
    path: "/twinflames",
    key: "twinflames",
    exact: true,
    address: ["0x495f947276749Ce646f68AC8c248420045cb7b5e"],
    Component: TwinFlamesIFrame,
    photoUrl: "TwinFlames.jpeg",
  },
  {
    title: "Avid Lines",
    path: "/avid-lines",
    key: "avidlines",
    exact: true,
    address: ["0xDFAcD840f462C27b0127FC76b63e7925bEd0F9D5"],
    Component: AvidLinesIFrame,
    photoUrl: "avidlines.jpeg",
  },
  {
    title: "BAYC",
    path: "/bayc",
    key: "bayc",
    exact: true,
    address: ["0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d"],
    Component: BaycIFrame,
    photoUrl: "bayc.png",
  },
  {
    title: "Chromie Squiggles",
    path: "/chromie-squiggles",
    key: "chromie-squiggles",
    exact: true,
    address: ["0x059edd72cd353df5106d2b9cc5ab83a52287ac3a"],
    Component: SquigglesIFrame,
    photoUrl: "chromie-squiggle.png",
  },
  {
    title: "Ringers",
    path: "/ringers",
    key: "ringers",
    exact: true,
    address: ["0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270"],
    Component: RingersIFrame,
    photoUrl: "ringers.png",
  },
  {
    title: "Fidenza",
    path: "/fidenza",
    key: "fidenza",
    exact: true,
    address: ["0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270"],
    Component: FidenzaIFrame,
    photoUrl: "fidenza.png",
  },
  {
    title: "Hashmasks",
    path: "/hashmasks",
    key: "hashmasks",
    address: ["0xc2c747e0f7004f9e8817db2ca4997657a7746928"],
    exact: true,
    Component: HashmaskIFrame,
    photoUrl: "hashmasks.png",
  },
  {
    title: "MoonCat Rescue",
    path: "/mooncat-rescue",
    key: "mooncat-rescue",
    address: ["0xc3f733ca98e0dad0386979eb96fb1722a1a05e69"],
    exact: true,
    Component: MoonCatIFrame,
    photoUrl: "mooncats.png",
  },
  {
    title: "Redlion Gazette",
    path: "/redlion-gazette",
    key: "redlion",
    address: ["0x21e783ef5890137593d8280ce7fb7e6515179d0f"],
    exact: true,
    Component: RedLionIFrame,
    photoUrl: "redlion.jpeg",
  },
  // {
  //   title: "Cryptovoxels",
  //   path: "/cryptovoxels",
  //   key: "cryptovoxels",
  //   exact: true,
  //   Component: CryptoVoxelsIFrame,
  //   photoUrl: "cryptovoxel.png",
  // },
];
