import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class CreatureToadzIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="CreatureToaz"
        src="https://datastudio.google.com/embed/reporting/c352d4e9-6695-4c86-bab9-3ea59d8be4bd/page/MTodC"
      />
    );
  }
}
