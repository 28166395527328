import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class AvidLinesIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Avid Lines"
        src="https://datastudio.google.com/embed/reporting/97c4448d-5705-409b-9e97-9660a651e572/page/Vj5XC"
      />
    );
  }
}
