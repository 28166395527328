import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class VeeFriendsIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="VeeFriends"
        src="https://datastudio.google.com/embed/reporting/1111c940-4198-4ed1-aa64-bd26e80da1fb/page/AkYNC"
      />
    );
  }
}
