// Here is the logic id like to map out here:

// 9. Avidlines: if 0 = 0.  1 - 3: 30, 4-6: 50, 7-15: 70, 16+: 100
function AvidLinesLogic(val) {
  if (val > 6) {
    return 100;
  }
  if (val > 3 && val < 7) {
    return 90;
  }
  if (val > 0 && val < 4) {
    return 70;
  }

  return 0;
}
// 1. Punks Balance: if = 0. 0.   1-3: 70,   4-6: 90   6+: 100
function PunksLogic(val) {
  if (val > 6) {
    return 100;
  }
  if (val > 3 && val < 7) {
    return 90;
  }
  if (val > 0 && val < 4) {
    return 70;
  }

  return 0;
}
// 2. AutoGlyph Balance: if 0 = 0.  1 +: 100
function AutoglyphsLogic(val) {
  if (val > 0) {
    return 100;
  }
  return 0;
}
// 3. Mooncats: if 0=0,  if 1-5: 30,  6-10:  60, 11-15: 80,   16+: 100
function MoonCatsLogic(val) {
  if (val > 15) {
    return 100;
  }
  if (val > 0 && val < 6) {
    return 30;
  }
  if (val > 5 && val < 11) {
    return 60;
  }
  if (val > 10 && val < 16) {
    return 80;
  }

  return 0;
}
// 5. Hashmask: if0=0, if 1-5: 20, 6-10: 40, 11-50: 70, 50+: 100
function HashMasksLogic(val) {
  if (val > 0) {
    return 100;
  }
  return 0;
}
// 4. Artblocks new and old combined. : if0=0, if 1-5: 10, 6-10: 30, 10-20: 50, 21-40: 70, 40-60: 90, 60+: 100
function ArtBlocksLogic(val) {
  if (val > 59) {
    return 100;
  }
  if (val > 39 && val < 61) {
    return 90;
  }

  if (val > 0 && val < 6) {
    return 10;
  }
  if (val > 5 && val < 11) {
    return 30;
  }
  if (val > 10 && val < 21) {
    return 50;
  }
  if (val > 20 && val < 41) {
    return 70;
  }

  return 0;
}

// 6. VeeFriends: if 0=0, if 1-3: 20, 4-7:50, 8-11: 70 12-18: 90, 19+: 100
function VeeFriendsLogic(val) {
  if (val > 18) {
    return 100;
  }
  if (val > 11 && val < 19) {
    return 90;
  }
  if (val > 7 && val < 12) {
    return 70;
  }
  if (val > 3 && val < 8) {
    return 50;
  }
  if (val > 0 && val < 4) {
    return 20;
  }

  return 0;
}
// 8. Avistars: if0=0, if 1-5: 20, 6-10: 40, 11-50: 70, 50+: 100
function AvastarsLogic(val) {
  if (val > 49) {
    return 100;
  }
  if (val > 10 && val < 51) {
    return 70;
  }
  if (val > 5 && val < 11) {
    return 40;
  }
  if (val > 0 && val < 6) {
    return 20;
  }

  return 0;
}
// 10. BAYC: if = 0. 0.   1: 50,   2-4: 80   5+: 100
function BAYCLogic(val) {
  if (val > 4) {
    return 100;
  }
  if (val > 1 && val < 5) {
    return 80;
  }
  if (val === 1) {
    return 50;
  }

  return 0;
}
// 7. Meebits: if 0=0, if 1-5: 15, 6-10:40, 11-20: 60, 21-60: 80, 60+: 100
function MeeBitsLogic(val) {
  if (val > 59) {
    return 100;
  }
  if (val > 20 && val < 61) {
    return 80;
  }
  if (val > 10 && val < 21) {
    return 60;
  }
  if (val > 5 && val < 11) {
    return 40;
  }
  if (val > 0 && val < 6) {
    return 15;
  }
  return 0;
}
function calcProjectScore(name, score) {
  switch (name) {
    case "mooncats":
      return MoonCatsLogic(score);
    case "autoglyphs":
      return AutoglyphsLogic(score);
    case "punks":
      return PunksLogic(score);
    case "hashmasks":
      return HashMasksLogic(score);
    case "artBlocksNew":
    case "artBlocksOld":
      return ArtBlocksLogic(score);
    case "VeeFriends":
      return VeeFriendsLogic(score);
    case "Avastars":
      return AvastarsLogic(score);
    case "AvidLines":
      return AvidLinesLogic(score);
    case "BAYC":
      return BAYCLogic(score);
    case "meebits":
      return MeeBitsLogic(score);
    default:
      return 0;
  }
}

export function calcScore(scoresArray) {
  const score = scoresArray.reduce((acc, obj) => {
    const projectScore = calcProjectScore(obj.name, obj.score);
    return projectScore + acc;
  }, 0);

  const projectsWithScore = scoresArray.map((obj) => {
    const projectScore = calcProjectScore(obj.name, obj.score);
    return { ...obj, total: projectScore };
  });

  //Sum score.  Divided by 1000
  return { score, projectsWithScore };
  // return score / 1000;
}
