import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class DoodlesIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Doodles"
        src="https://datastudio.google.com/embed/reporting/21f144fe-bbd8-4fd0-a61b-71732bf692d7/page/WkndC"
      />
    );
  }
}
