import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class CryptoPunk extends React.Component {
  render() {
    return (
      <IframeComp
        title="Crypto Punks"
        src="https://datastudio.google.com/embed/reporting/c07f0e57-5485-4ac7-b798-1b1058f1a845/page/Dj3YC"
      />
    );
  }
}
