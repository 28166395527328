import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class ForgottenRuneIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="ForgottenRune"
        src="https://datastudio.google.com/embed/reporting/9a96097a-9928-4a2d-a64f-33a35a86d2c4/page/CNndC"
      />
    );
  }
}
