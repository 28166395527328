import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/free-solid-svg-icons";

import { LeftNav } from "../../components/left-nav/left-nav";

import "./header.scss";
import logo from "../../assets/imgs/logo-top-2.png";
// import MetaMask from "../../assets/company-logos/meta-mask.png";

export const Header = (props) => {
  const showProjectsLink = props.showProjects ?? true;

  const [showProjects, setShowProjects] = useState(false);

  function onClick() {
    setShowProjects(!showProjects);
  }

  return (
    <header>
      <div className="title-container">
        <div className="logo">
          <Link to="/">
            <img className="logo-img" src={logo} alt="DEGEN DATA LOGO" />
          </Link>
        </div>
        <Link to="/">
          <div className="title">DEGEN DATA</div>
          <small className="sub-title">De-Generative NFT Data Lab</small>
        </Link>
      </div>
    </header>
  );
};
