import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class ArtBlocksPlaygroundIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Artblocksplayground"
        src="https://datastudio.google.com/embed/reporting/86896348-1a88-4de6-9ffd-4bd1d2d5b349/page/X36WC"
      />
    );
  }
}
