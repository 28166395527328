import React from "react";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";
import { SubNav } from "../sub-nav/sub-nav";
import "./layout.scss";

export const Layout = (props) => {
  const showLeft = props.showLeftNav ?? true;
  const showProjects = props.showProjects ?? true;

  return (
    <div className={`default-layout ${showLeft ? "" : "no-left-nav"}`}>
      <Header showProjects={showProjects} />
      {/* {showLeft && <LeftNav activeRoute={props.activeRoute} />} */}
      {/* <SubNav /> */}
      <div className="content">{props.children}</div>
      <Footer />
    </div>
  );
};
