import React from "react";
import { IframeComp } from "../iframe-container/iframe-comp";

export default class TwinFlamesIFrame extends React.Component {
  render() {
    return (
      <IframeComp
        title="Twin Flames"
        src="https://datastudio.google.com/embed/reporting/0c19e60b-a26b-48ff-939a-99c2fae1a3f0/page/XUoYC"
      />
    );
  }
}
